<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Add Operators
      </h1>

      <div class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4">
        <!-- component -->
        <form method="post" @submit.prevent="addNew">
          <div class="flex flex-col">
            <div class="mb-6" id="message"></div>
            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="username"
              >
                Name
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="name"
                type="text"
                placeholder="Name"
                v-model="name"
              />
              <p class="text-red text-xs italic" v-if="error && !name">
                Please enter a name.
              </p>
            </div>
            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="username"
              >
                Username
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="username"
                type="text"
                placeholder="Username"
                v-model="username"
              />
              <p class="text-red text-xs italic" v-if="error && !username">
                Please enter a username.
              </p>
            </div>
            <div class="mb-2">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="password"
              >
                Password
              </label>
              <input
                class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
                id="password"
                type="password"
                placeholder="***********"
                autocomplete="off"
                v-model="password"
              />
              <p
                class="text-red text-xs italic"
                v-if="(error && !password) || (error && password.length < 8)"
              >
                Password must be greater than or equal to 8 chars.
              </p>
            </div>
            <div class="mb-6">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="password"
              >
                Role
              </label>
              <select
                class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
                id="role"
                type="role"
                autocomplete="off"
                v-model="role"
              >
                <option value="">Select role</option>
                <option value="L1">Super Admin</option>
                <option value="L2">Admin</option>
                <option value="L3">Employee</option>
              </select>
              <p class="text-red text-xs italic" v-if="error && !role">
                Please select role
              </p>
            </div>
            <div class="flex items-center justify-between">
              <button
                v-if="!loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Submit
              </button>
              <button
                v-if="loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                type="submit"
                disabled
              >
                Submitting...
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "AddOperator",
  data() {
    return {
      sidebarOpen: true,
      name: "",
      username: "",
      password: "",
      role: "",
      error: false,
      loading: false
    };
  },
  methods: {
    addNew() {
      this.error = false;
      if (!this.name) {
        this.error = true;
        return;
      }
      if (!this.username) {
        this.error = true;
        return;
      }
      if (!this.password || this.password.length < 8) {
        this.error = true;
        return;
      }
      if (!this.role) {
        this.error = true;
        return;
      }
      this.loading = true;
      this.$axios
        .post(`${this.$api}/operator/add`, {
          name: this.name,
          username: this.username,
          password: this.password,
          role: this.role
        })
        .then(res => {
          console.log(res);
          this.loading = false;
          if (res.data.success) {
            document.getElementById("message").innerHTML = `
              <span style="color:#3c763d;font-weight:bold">Operator added success</span>
            `;
            this.name = "";
            this.username = "";
            this.password = "";
            this.role = "";
          } else {
            document.getElementById("message").innerHTML = `
              <span style="color:#a94442;font-weight:bold">${res.data.message}</span>
            `;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          document.getElementById("message").innerHTML = `
              <span style="color:#a94442;font-weight:bold">Something went wrong, Please try again later</span>
            `;
        });
      setTimeout(() => {
        document.getElementById("message").innerHTML = ``;
      }, 6000);
    }
  }
};
</script>
